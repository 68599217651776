import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CategorySelector from '../../_common/category/CategorySelector';
import {
  ActionType,
  Flow,
  useBookingContext,
} from '../../_common/context/BookingContext';
import { useCategoryContext } from '../../_common/context/CategoryContext';
import useFeatureFlag from '../../_common/utils/hooks/useFeatureFlag';
import { getSessionId } from '../../_common/utils/session';
import { AuthMethod, getDecodedToken, logout } from '../../_common/utils/token';
import { UPDATE_CONTEXT } from '../../modules/booking/customerForm/mutations';
import { FIELDS_LIST } from '../../modules/booking/customerForm/queries';
import { FieldsListQuery, UpdateContext } from '../../types/api';
import { BOOKING_PATH, SELF_SERVICE_PATH } from '../../utils/constants';
import { User } from '../User/User';

import { InstanceLogo } from './InstanceLogo';

export function InstanceHeader() {
  const { t } = useTranslation();
  const sessionId = getSessionId();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const [{ categoryId }, dispatch] = useBookingContext();
  const { categories, categorySelectionEnabled } = useCategoryContext();
  const isMaintenanceMode = useFeatureFlag('maintenance.enabled');

  const { accessLevel, firstName, flow, id, lastName, method } =
    getDecodedToken() ?? {};

  const showCategorySelector =
    categorySelectionEnabled &&
    categoryId != null &&
    categories.length > 1 &&
    !isMaintenanceMode;

  const showStopButton =
    (pathname === SELF_SERVICE_PATH || method === AuthMethod.CONTEXT) &&
    categoryId != null;

  const { data: fieldsListData } = useQuery<FieldsListQuery>(FIELDS_LIST, {
    variables: { categoryId, active: true },
    skip: categoryId == null,
  });
  const redirectUrl =
    (fieldsListData?.category.fields ?? []).find(
      (field) => field.key === 'redirectUrl',
    )?.defaultValue ?? undefined;

  const [updateContext] = useMutation<UpdateContext>(UPDATE_CONTEXT);

  async function handleLogout() {
    let redirectPath = '/login';

    if (showStopButton) {
      if (redirectUrl !== undefined) {
        return (window.location.href = redirectUrl);
      }

      redirectPath =
        flow === Flow.SELF_SERVICE || pathname === SELF_SERVICE_PATH
          ? SELF_SERVICE_PATH
          : BOOKING_PATH;
    }

    dispatch({ type: ActionType.RESET_INITIAL_STATE });

    history.push(redirectPath);

    await logout();

    return;
  }

  function handleNavigateStart() {
    if (
      history.location.pathname === '/confirm' ||
      history.location.pathname.startsWith('/admin')
    ) {
      history.push({
        pathname:
          flow === Flow.SELF_SERVICE || pathname === SELF_SERVICE_PATH
            ? SELF_SERVICE_PATH
            : BOOKING_PATH,
      });
    }

    dispatch({
      type: ActionType.CHANGE_CATEGORY,
      payload: categoryId,
    });

    return updateContext({
      variables: {
        input: {
          attributes: [],
        },
      },
      update: () => {
        history.push({
          pathname: history.location.pathname,
        });
      },
    });
  }

  return (
    <div className="relative flex flex-wrap items-center justify-between px-3 py-2 w-full bg-white border-b border-gray-300 space-x-0 md:space-x-4 lg:space-x-8">
      <div className="w-1/2 md:w-auto xl:w-1/3">
        <InstanceLogo onClick={handleNavigateStart} />
      </div>
      {showCategorySelector && (
        <Route path={[BOOKING_PATH, SELF_SERVICE_PATH]}>
          <div className="order-last mt-4 w-full md:flex-1 md:order-2 md:mt-0 xl:w-1/3">
            <div className="md:mx-auto md:max-w-xl">
              <CategorySelector
                label={t('translation:select.category')}
                name={t('translation:select.category')}
              />
            </div>
          </div>
        </Route>
      )}
      <div className="flex justify-end order-3 ml-auto w-1/2 space-x-4 md:ml-0 md:w-auto xl:w-1/3">
        {id !== undefined && (
          <>
            {method !== AuthMethod.CONTEXT && (
              <div title={`Id: ${id}\nSession: ${sessionId}`}>
                <User
                  firstName={firstName ?? ''}
                  lastName={lastName ?? ''}
                  onClick={() => history.push(`/user${search}`)}
                />
              </div>
            )}
            {accessLevel !== undefined && accessLevel <= 2 && (
              <Switch>
                <Route path={[BOOKING_PATH, SELF_SERVICE_PATH, '/error']}>
                  <div className="hidden md:flex md:justify-center">
                    <Link
                      className="btn btn-tertiary text-brand-400"
                      to={`/admin${search}`}
                    >
                      {t('instance_settings_button')}
                    </Link>
                  </div>
                  <div className="flex justify-center md:hidden">
                    <Link className="btn text-brand-400" to={`/admin${search}`}>
                      <FontAwesomeIcon
                        icon={['fas', 'user-shield']}
                        size="lg"
                      />
                    </Link>
                  </div>
                </Route>
              </Switch>
            )}
            <Route path={['/user', '/admin']}>
              <div className="hidden md:flex md:justify-center">
                <Link
                  className="btn btn-tertiary text-brand-400"
                  to={`${BOOKING_PATH}${search}`}
                >
                  {t('go_to_booking_button')}
                </Link>
              </div>
              <div className="flex justify-center md:hidden">
                <Link
                  className="btn text-brand-400"
                  to={`${BOOKING_PATH}${search}`}
                >
                  <FontAwesomeIcon icon={['fas', 'arrow-left']} size="lg" />
                </Link>
              </div>
            </Route>
          </>
        )}
        {(id !== undefined || showStopButton) && (
          <>
            <div className="hidden md:flex md:justify-center">
              <button
                className="btn btn-tertiary text-brand-400"
                onClick={handleLogout}
              >
                {showStopButton
                  ? t('login:common.stop')
                  : t('login:common.logout')}
              </button>
            </div>
            <div className="flex justify-center md:hidden">
              <button className="btn text-brand-400" onClick={handleLogout}>
                <FontAwesomeIcon icon={['fas', 'power-off']} size="lg" />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
