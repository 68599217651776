import { gql } from '@apollo/client';

export const UPDATE_CONTEXT = gql`
  mutation UpdateContext($input: UpdateContextInput!) {
    updateContext(input: $input) {
      contextId
      callback {
        enabled
        buttons {
          label
          value
        }
      }
      attributes {
        value
        field {
          id
          key
          dataType
          skedifyCustomerAttribute
        }
      }
      subjects {
        id
        description
        title
      }
      leadSegment {
        id
      }
    }
  }
`;
