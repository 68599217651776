import { gql } from '@apollo/client';

export const FIELDS_LIST = gql`
  query FieldsListQuery($categoryId: String!, $active: Boolean) {
    category(categoryId: $categoryId, active: $active) {
      key
      fields {
        id
        categoryFieldConfigurationId
        usedForQualification
        requiredForQualification
        conditionalActions {
          condition
          actions {
            type
            payload
          }
        }
        section
        required
        order
        inputType
        dataType
        defaultValue
        visible
        icon
        key
        fieldGroup {
          id
          icon
          label
        }
        label
        validators {
          regex
          errorMessage
        }
        options {
          label
          value
        }
      }
    }
  }
`;

export const ATTRIBUTES = gql`
  query AttributesQuery($contextId: String!) {
    context(contextId: $contextId) {
      contextId
      attributes {
        value
        field {
          id
          key
          dataType
          skedifyCustomerAttribute
        }
      }
      filters {
        contacts
        location {
          countryCode
          postalCode
        }
      }
      category {
        id
      }
    }
  }
`;

export const LEAD_SEGMENT = gql`
  query LeadSegmentQuery($contextId: String!) {
    context(contextId: $contextId) {
      leadSegment {
        id
      }
    }
  }
`;
