import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeContext } from 'styled-components';

interface Props {
  onClick: () => void;
}

export function InstanceLogo(props: Props) {
  const { onClick } = props;
  const { instanceLogo } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <button aria-label={t('translation:button.home')} onClick={onClick}>
      {instanceLogo != null && instanceLogo !== '' ? (
        <img
          alt="instance logo"
          className="h-8 cursor-pointer"
          src={instanceLogo}
        />
      ) : (
        <FontAwesomeIcon icon={['fas', 'home']} />
      )}
    </button>
  );
}
